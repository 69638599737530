import React, { useState } from 'react'
import Layout from '../../components/common/Layout'
import Fade from 'react-reveal/Fade'
import CTA from '../../components/common/CTA'
import FAQ from '../../components/common/FAQ'
import PricingPlans from '../../components/pricing/PricingPlans'
import PageHeader from '../../components/common/PageHeader'
import ContentSection from '../../components/common/ContentSection'
import ElevateSection from '../../components/common/ElevateSection'
import Button from '../../components/common/Button'
import { Space } from '@mantine/core'
import Spotlight from '../../components/home/Spotlight'

const RetailMarketingPage = () => {
  return (
    <Layout
      meta={{
        description:
          'Use spatial intelligence to optimize your retail and marketing strategies. Understand your customers better, and make data-driven decisions.',
        title: 'Atlas for Retail & Marketing',
        type: 'website'
      }}
      title={'Atlas for Retail & Marketing'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader
            title={'Retail & Marketing'}
            description={
              'Use spatial intelligence to optimize your retail and marketing strategies. Understand your customers better, and make data-driven decisions.'
            }
            video="https://cdn.atlas.co/landing-pages/retail-hero.mp4"
          />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection centered>
            <h2>Gain market insights faster</h2>
            <p>
              The future success of retail, real estate, and restaurants will
              largely depend on the competitive advantages gained from investing
              in and implementing smart technology.
            </p>
          </ContentSection>
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={
              'https://cdn.atlas.co/landing-pages/visualize-and-style-data.mp4'
            }
            icon={{ src: '/icons/user-group.svg', color: '#FFBF6E' }}
          >
            <h3>Demographic Analysis</h3>
            <p>
              Understand the demographic makeup of your customers and target new
              markets.
            </p>
            <p>
              Gaining insight into your customers' socioeconomic status and
              purchasing behavior is crucial for making informed business
              decisions.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/dynamic-dashboards.mp4'}
            reverse
            icon={{ src: '/icons/strategy.svg', color: '#C2E5FF' }}
          >
            <h3>Trade Area Analysis</h3>
            <p>
              Understanding the spatial context is crucial to the commercial
              real estate market. An inadequate business location can determine
              the success or failure of a business, regardless of the quality of
              its products or services.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/investment-analysis.mp4'}
            icon={{
              src: '/icons/list-magnifying-glass.svg',
              color: '#FFBF6E'
            }}
          >
            <h3>Competitive Market Analysis</h3>
            <p>Losing customers to competitors is costly.</p>
            <p>
              Understanding the products, promotions, and services that appeal
              to profitable customers in your trade area can foster customer
              loyalty and prevent cannibalization.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/site-selection.mp4'}
            reverse
            icon={{ src: '/icons/map-pin-area.svg', color: '#CDE4E1' }}
          >
            <h3>Site Selection</h3>
            <p>
              GIS can assist in locating the ideal site for your next store,
              distribution center, or service department.
            </p>
            <p>
              By integrating customer surveys with census data, GIS allows you
              to visualize market penetration, market share, and trade areas.
              Additionally, when markets shift, GIS can aid in planning exit
              strategies and asset disposal.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ElevateSection
            title={'Designed to be easily updated'}
            subtitle={
              'Web maps are live assets designed to be edited, and any changes will be automatically updated synced across all members.'
            }
          />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <Spotlight />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export default RetailMarketingPage
